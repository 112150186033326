import React from "react";
import styled from "styled-components";
import Layout from "../Layout";

import { useAuth } from "../Auth";
import { Navigate } from "react-router-dom";

const LoginContainer = styled.div`
    padding: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-size: 28px;
        line-height: 33px;
        color: #000000;
        font-weight: 700;
        margin-bottom: 20px;
    }

    a {
        background: #DC6126;
        color: #fff;
        border-radius: 30px;
        font-weight: 500;
        padding: 10px 32px;
        font-size: 16px;
        line-height: 22px;
        text-decoration: none;
    }
`;

export default function Login() {
  const { authenticated } = useAuth();

  if (authenticated) {
    return <Navigate to="/" replace />
  }

  return (
    <Layout>
      <LoginContainer>
        <h1>Please login to view the myCUES Dashboard.</h1>
        <a href="https://www.cues.org/acgi/login">LOG IN</a>
      </LoginContainer>
    </Layout>
  );
}
