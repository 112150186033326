import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Player from "@vimeo/player";
import { useAuth } from "../../Auth";
import ReactGA from "react-ga4";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

// import required modules
import { Grid, Pagination } from "swiper";

const BenefitsElement = styled.section`
  display: flex;
  justify-content: center;
  padding: 57px 20px 52px 20px;
  background: #f1f1f1;

  .cmp-benefits-container {
    max-width: 1154px;
    width: 100%;
  }

  .cmp-benefits-header {
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    @media (max-width: 1100px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .cmp-benefits-header-content {
      h2 {
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: -0.48px;
        color: #333333;
        margin-right: 40px;
      }

      p {
        font-size: 12px;
        line-height: 14px;
        font-weight: 500;
        color: #607588;
        margin-bottom: 5px;
      }
    }

    ul.cmp-benefits-tabs {
      display: flex;

      @media (max-width: 1100px) {
        margin-top: 22px;
      }

      li {
        &:not(:last-child) {
          margin-right: 53px;
        }

        button {
          background: none;
          border: none;
          font-size: 18px;
          font-weight: 700;
          line-height: 21px;
          letter-spacing: -0.36px;
          color: #6a6d75;
          padding: 0;

          span {
            color: #dc6126;
          }

          &:hover {
            color: #303440;
            cursor: pointer;
          }

          &.active {
            color: #303440;
            position: relative;

            &::after {
              content: " ";
              position: absolute;
              height: 3px;
              left: 0;
              right: 0;
              bottom: -10px;
              background: #dc6126;
            }
          }
        }
      }
    }

    a {
      margin-left: auto;
      border-radius: 30px;
      background: #dc6126;
      border: 2px solid #dc6126;
      text-decoration: none;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0;
      padding: 4px 24px;
    }
  }

  .cmp-benefits-card {
    background-size: cover !important;
    background-repeat: no-repeat;
    max-width: 365px;
    width: 100%;
    height: 240px;
    padding: 36px 33px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 7px;
    background: #fff;
    box-sizing: border-box;

    @media (max-width: 1100px) {
      max-width: 100%;
    }

    p.cmp-benefits-card-title {
      font-size: 18px;
      line-height: 21px;
      font-weight: 700;
      color: #303440;
      margin-bottom: 15px;
    }

    p.cmp-benefits-card-description {
      font-size: 14px;
      line-height: 22px;
      color: #303440;
    }

    a {
      font-size: 14px;
      line-height: 16px;
      color: #dc6126;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: 700;
      display: flex;
      align-items: center;
      margin-top: auto;
      margin-left: auto;

      img {
        height: 16px;
        width: 16px;
        margin-left: 10px;
      }
    }

    p,
    a {
      display: none;
    }

    &:hover {
      background-image: none !important;

      p {
        display: block;
      }

      a {
        display: flex;

        align-items: flex-end;
      }
    }
  }

  .cmp-key-benefits {
    display: flex;
    align-items: center;

    @media (max-width: 1100px) {
      flex-direction: column;
    }

    .cmp-key-benefits-content {
      max-width: 338px;
      width: 100%;
      margin-right: 56px;

      @media (max-width: 1100px) {
        max-width: 100%;
        margin-right: unset;
      }

      h3 {
        font-size: 24px;
        line-height: 28px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      & > p {
        font-size: 16;
        line-height: 26px;
        color: #303440;
        padding-right: 12px;
      }

      .cmp-key-benefits-video-embed {
        margin-top: 43px;
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        max-width: 100%;

        @media (max-width: 1100px) {
          margin-top: 20px;
        }

        iframe,
        object,
        embed {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    .cmp-key-benefits-cards {
      max-width: 762px;
      width: 100%;
      align-self: flex-start;

      @media (max-width: 1100px) {
        max-width: 100%;
        margin-top: 32px;
      }

      ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 30px;
        grid-row-gap: 30px;

        @media (max-width: 680px) {
          grid-template-columns: 1fr;
        }

        li {
        }
      }
    }
  }

  .cmp-all-benefits {
    .swiper {
    }

    .swiper-wrapper {
      height: 562px;
    }

    .swiper-slide {
      height: unset;
    }

    .swiper-pagination {
      bottom: 0px;

      .swiper-pagination-bullet {
        height: 15px;
        width: 15px;
        background: none;
        border: 2px solid #707070;
        opacity: 1;

        &:not(:last-of-type) {
          margin-right: 10px;
        }

        &.swiper-pagination-bullet-active {
          background: #303440;
          border: 2px solid #303440;
        }
      }
    }
  }

  .cmp-member-resources {
    .cmp-member-resource-cards {
      ul {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 30px;
        grid-row-gap: 30px;

        @media (max-width: 1100px) {
          grid-template-columns: 1fr 1fr;
        }

        @media (max-width: 767px) {
          grid-template-columns: 1fr;
          grid-row-gap: 20px;
        }

        li {
        }
      }
    }
  }

  .cmp-member-resource-card {
    max-width: 365px;
    width: 100%;
    height: 240px;
    padding: 32px 25px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 7px;
    background: #fff;
    box-sizing: border-box;
    align-items: flex-start;

    @media (max-width: 1100px) {
      max-width: 100%;
    }

    img.cmp-member-resource-card-icon {
      width: auto;
      height: 30px;
      margin-bottom: 15px;
    }

    p.cmp-member-resource-card-title {
      font-size: 18px;
      line-height: 21px;
      font-weight: 700;
      color: #303440;
      margin-bottom: 6px;
    }

    p.cmp-member-resource-card-description {
      font-size: 14px;
      line-height: 22px;
      color: #303440;
    }

    a {
      font-size: 14px;
      line-height: 16px;
      color: #dc6126;
      text-transform: uppercase;
      text-decoration: none;
      font-weight: 700;
      display: flex;
      align-items: center;
      margin-top: auto;
      margin-left: auto;

      img {
        height: 16px;
        width: 16px;
        margin-left: 10px;
      }
    }
  }
`;

const Benefits = () => {
  const arrow = require("../../assets/images/icn-arrow-r.png");

  // Member Resource Icons
  const iconMembership = require("../../assets/images/icn-benefits-guidemembership.png");
  const iconWelcome = require("../../assets/images/icn-benefits-welcome.png");
  const iconReport = require("../../assets/images/icn-benefits-report.png");
  const iconLeadership = require("../../assets/images/icn-benefits-guideleadership.png");
  const iconOnboarding = require("../../assets/images/icn-benefits-onboarding.png");
  const iconArrowDown = require("../../assets/images/icn-benefits-down.png");
  const iconArrowRight = require("../../assets/images/icn-next-o.png");

  const [activeTab, setActiveTab] = useState("key-benefits");
  const [benefits, setBenefits] = useState("null");

  const { cmpData } = useAuth();

  function handleGAEvent(eventTitle, value) {
    if (cmpData) {
      if (window.location.host == "mycues.cues.org") {
        ReactGA.event(eventTitle, {
          value,
          user_role: cmpData.account_info.acgiRole,
          user_memberlevel: cmpData.account_info.membershipLevel,
          user_company: cmpData.account_info.employer,
        });
      }
    }
  }

  const videoPlayer = useRef(null);

  useEffect(() => {
    switch (activeTab) {
      case "key-benefits":
        setBenefits(cmpData.benefits.keyBenefits);
        break;
      case "all-benefits":
        setBenefits(cmpData.benefits.allBenefits);
        break;
      case "exclusive-resources":
        setBenefits(cmpData.benefits.resources);
        break;
      default:
        console.log("I don't know what benefits to show...");
    }
  }, [activeTab]);

  useEffect(() => {
    // Track when the user plays the key benefits video.
    const player = new Player(videoPlayer.current);
    player.on("play", function () {
      handleGAEvent("benefits_keybenefitsvideo");
    });
  }, []);

  return (
    <>
      {benefits && cmpData && (
        <BenefitsElement>
          <div className="cmp-benefits-container">
            {/* Header */}
            <div className="cmp-benefits-header">
              <div className="cmp-benefits-header-content">
                <p>Your Membership Level</p>
                <h2>
                  {cmpData.account_info && cmpData.account_info.membershipLevel
                    ? cmpData.account_info.membershipLevel.split("MVP ").pop()
                    : "N/A"}
                </h2>
              </div>

              <ul className="cmp-benefits-tabs">
                <li>
                  <button
                    className={activeTab == "key-benefits" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("key-benefits");
                    }}
                  >
                    Key Benefits
                  </button>
                </li>
                <li>
                  <button
                    className={activeTab == "all-benefits" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("all-benefits");
                    }}
                  >
                    All{" "}
                    {cmpData.account_info.membershipLevel &&
                      cmpData.account_info.membershipLevel
                        .split("MVP ")
                        .pop()}{" "}
                    Member Benefits
                  </button>
                </li>
                <li>
                  <button
                    className={
                      activeTab == "exclusive-resources" ? "active" : ""
                    }
                    onClick={() => {
                      setActiveTab("exclusive-resources");
                    }}
                  >
                    Exclusive Member Resources
                  </button>
                </li>
              </ul>
            </div>

            {activeTab == "key-benefits" && (
              <>
                {/* Key Benefits Section */}
                <div className="cmp-key-benefits">
                  <div className="cmp-key-benefits-content">
                    <h3>Your Key Benefits</h3>

                    <p>
                      Wondering where to start? Easily access our most popular
                      benefits here. Want to see all your benefits? Simply
                      choose the all benefits selection.
                    </p>

                    {cmpData.account_info.membershipLevel ==
                      "MVP Individual" && (
                      <div className="cmp-key-benefits-video-embed">
                        <iframe
                          ref={videoPlayer}
                          src="https://player.vimeo.com/video/702196932?h=416f17e825"
                          frameborder="0"
                          allow="autoplay; fullscreen; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </div>
                    )}

                    {["MVP Unlimited", "MVP Unlimited+"].indexOf(
                      cmpData.account_info.membershipLevel
                    ) >= 0 && (
                      <div className="cmp-key-benefits-video-embed">
                        <iframe
                          ref={videoPlayer}
                          src="https://player.vimeo.com/video/702196464?h=416f17e825"
                          frameborder="0"
                          allow="autoplay; fullscreen; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </div>
                    )}
                  </div>

                  <div className="cmp-key-benefits-cards">
                    <ul>
                      {Object.values(benefits).map((benefit) => (
                        <li>
                          <div
                            className="cmp-benefits-card"
                            style={{
                              backgroundImage: benefit.image
                                ? `url('${benefit.image}')`
                                : "none",
                            }}
                          >
                            <p className="cmp-benefits-card-title">
                              {benefit.title}
                            </p>
                            <p className="cmp-benefits-card-description">
                              {benefit.description}
                            </p>
                            <a
                              href={benefit.link}
                              target="_blank"
                              onClick={() => {
                                handleGAEvent(
                                  "benefits_accesskeybenefits",
                                  benefit.title
                                );
                              }}
                            >
                              Access{" "}
                              <img src={iconArrowRight} alt="Visit CUES.net" />
                            </a>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </>
            )}

            {activeTab == "all-benefits" && (
              <>
                {/* All Benefits Section */}
                <div className="cmp-all-benefits">
                  <Swiper
                    slidesPerView={1}
                    grid={{
                      rows: 2,
                    }}
                    spaceBetween={30}
                    pagination={{
                      clickable: true,
                    }}
                    modules={[Grid, Pagination]}
                    className="mySwiper"
                    breakpoints={{
                      500: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                      },
                      900: {
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                      },
                    }}
                  >
                    {Object.values(benefits).map((benefit) => (
                      <SwiperSlide>
                        <div
                          className="cmp-benefits-card"
                          style={{
                            backgroundImage: benefit.image
                              ? `url('${benefit.image}')`
                              : "none",
                          }}
                        >
                          <p className="cmp-benefits-card-title">
                            {benefit.title}
                          </p>
                          <p className="cmp-benefits-card-description">
                            {benefit.description}
                          </p>
                          <a
                            href={benefit.link}
                            target="_blank"
                            onClick={() => {
                              handleGAEvent(
                                "benefits_allbenefits",
                                benefit.title
                              );
                            }}
                          >
                            Access{" "}
                            <img src={iconArrowRight} alt="Visit CUES.net" />
                          </a>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </>
            )}

            {activeTab == "exclusive-resources" && (
              <>
                {/* Member Resources Section */}
                <div className="cmp-member-resources">
                  <div className="cmp-member-resource-cards">
                    <ul>
                      {Object.values(benefits).map((benefit) => (
                        <li>
                          <div className="cmp-member-resource-card">
                            {benefit.image && (
                              <img
                                src={benefit.image}
                                className="cmp-member-resource-card-icon"
                                alt="Member Resource Icon"
                              />
                            )}
                            <p className="cmp-member-resource-card-title">
                              {benefit.title}
                            </p>

                            {benefit.description && (
                              <p className="cmp-member-resource-card-description">
                                {benefit.description}
                              </p>
                            )}

                            {benefit.link && (
                              <a href={benefit.link} target="_blank">
                                {benefit.title ==
                                "Board Governance Assessment" ? (
                                  <>
                                    Access{" "}
                                    <img
                                      src={iconArrowDown}
                                      alt="Download Resource"
                                    />
                                  </>
                                ) : (
                                  <>
                                    Download{" "}
                                    <img
                                      src={iconArrowDown}
                                      alt="Download Resource"
                                    />
                                  </>
                                )}
                              </a>
                            )}

                            {/* Only use the file download link if resource link field is null. */}
                            {benefit.link == null && benefit.file && (
                              <a
                                href={benefit.file}
                                target="_blank"
                                download
                                onClick={() => {
                                  handleGAEvent(
                                    "benefits_resources",
                                    benefit.title
                                  );
                                }}
                              >
                                {benefit.title ==
                                "Board Governance Assessment" ? (
                                  <>
                                    Access{" "}
                                    <img
                                      src={iconArrowDown}
                                      alt="Download Resource"
                                    />
                                  </>
                                ) : (
                                  <>
                                    Download{" "}
                                    <img
                                      src={iconArrowDown}
                                      alt="Download Resource"
                                    />
                                  </>
                                )}
                              </a>
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </>
            )}
          </div>
        </BenefitsElement>
      )}
    </>
  );
};

export default Benefits;
