import React from "react";
import { Link } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

export default function Layout(props) {
  return (
    <div>
      <Header />

      <main className="dashboard-content">
        {props.children}
      </main>

      <Footer />
    </div>
  );
}
