import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useAuth } from "../../Auth";

const DebugInfoEl = styled.section`
  display: flex;
  justify-content: center;
  background: #f1f1f1;
  padding: 34px 20px;

  h1 {
    color: #0c66a5;
    font-weight: 700;
    letter-spacing: -0.84px;
    font-size: 28px;
    line-height: 33px;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 18px;
    line-height: 21px;
    font-weight: 700;
    margin-bottom: 4px;
  }

  div {
    margin-bottom: 16px;
  }

  ul {
    list-style: disc;
    padding-left: 20px;
  }

  .debug-info {
    margin-bottom: 20px;
  }
`;

const Container = styled.div`
  background: #fff;
  box-shadow: 0px 3px 6px #0000001f;
  border-radius: 12px;
  max-width: 1280px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 34px;
`;

const DebugInfo = () => {
  const { cmpData } = useAuth();
  const { account_info } = cmpData;

  return (
    <>
      {account_info && (
        <DebugInfoEl>
          <Container>
            <h1>Debugging Info</h1>
            <p className="debug-info">This section will be removed once testing is complete.</p>

            <div>
              <h2>Current ACGI Role:</h2>
              <p>{account_info.acgiRole ? account_info.acgiRole : "None"}</p>
            </div>

            <div>
              <h2>CUES Personas:</h2>
              <ul>
                {account_info.cuesPersonas ? (
                  account_info.cuesPersonas.map((persona) => (
                    <li>
                      <p>{persona}</p>
                    </li>
                  ))
                ) : (
                  <li>
                    <p>None</p>
                  </li>
                )}
              </ul>
            </div>

            <div>
              <h2>CU Management Personas:</h2>
              <ul>
                {account_info.cmanPersonas ? (
                  account_info.cmanPersonas.map((persona) => (
                    <li>
                      <p>{persona}</p>
                    </li>
                  ))
                ) : (
                  <li>
                    <p>None</p>
                  </li>
                )}
              </ul>
            </div>
          </Container>
        </DebugInfoEl>
      )}
    </>
  );
};

export default DebugInfo;
