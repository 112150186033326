import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const FooterEl = styled.footer`
  background: #303440;
  display: flex;
  justify-content: center;
  padding: 20px 0 200px 0;
`;

const FooterContainer = styled.div`
  max-width: 1090px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FooterLogoImg = styled.img`
  max-width: 266px;
  width: 100%;
  height: auto;
`;

const FooterLogo = () => {
  return (
    <Link to="/">
      <FooterLogoImg src="logo.png" />
    </Link>
  );
};

const SocialMediaIcon = ({ to, src, alt }) => {
  return (
    <a href={to}>
      <img src={src} alt={alt} />
    </a>
  );
};

const SocialMediaIconList = styled.ul`
  list-style: none;
  display: flex;
  margin: 20px 0;

  li {
    margin-right: 20px;

    a {
      img {
        max-width: 24px;
        width: 100%;
        height: auto;
      }
    }
  }
`;

const SocialMediaIcons = () => {
  return (
    <SocialMediaIconList>
      <li>
        <SocialMediaIcon
          to="https://twitter.com/TheRealCUES"
          src="icn-social-twitter.png"
          alt="Twitter icon"
        />
      </li>
      <li>
        <SocialMediaIcon
          to="https://www.facebook.com/therealcues"
          src="icn-social-facebook.png"
          alt="Facebook icon"
        />
      </li>
      <li>
        <SocialMediaIcon
          to="https://www.linkedin.com/company/cues_2"
          src="icn-social-linkedin.png"
          alt="LinkedIn icon"
        />
      </li>
      <li>
        <SocialMediaIcon
          to="https://www.instagram.com/therealcues/"
          src="icn-social-instagram.png"
          alt="Instagram icon"
        />
      </li>
    </SocialMediaIconList>
  );
};

const FooterNavContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  border-top: solid 1px #707070;
  padding-top: 20px;
  margin-bottom: 20px;

  ul {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      li {
          &:not(:last-child) {
              margin-right: 20px;
          }

          a {
              color: #fff;
              text-decoration: none;
          }
      }
  }
`;

const FooterNav = () => {
  return (
    <FooterNavContainer>
      <ul>
        <li>
          <a href="https://www.cues.org/event-cancellation-policy" target="_blank">Event Cancellation Policy</a>
        </li>
        <li>
          <a href="https://www.cues.org/cues-bylaws" target="_blank">CUES Bylaws</a>
        </li>
        <li>
          <a href="https://www.cues.org/terms-and-conditions-use" target="_blank">Terms & Conditions</a>
        </li>
        <li>
          <a href="https://www.cues.org/privacy-policy" target="_blank">Privacy Policy</a>
        </li>
        <li>
          <a href="https://www.cues.org/accessibility-statement" target="_blank">Accessibility Statement</a>
        </li>
        <li>
          <a href="https://www.cues.org/sitemap" target="_blank">Sitemap</a>
        </li>
      </ul>
    </FooterNavContainer>
  );
};

const Copyright = styled.p`
    color: #fff;
`

const Footer = () => {
  return (
    <FooterEl>
      <FooterContainer>
        <FooterLogo />
        <SocialMediaIcons />
        <FooterNav />
        <Copyright>© 2022 Credit Union Executives Society</Copyright>
      </FooterContainer>
    </FooterEl>
  );
};

export default Footer;
