import react, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Cookies from "js-cookie";
import Player from "@vimeo/player";
import { useAuth } from "./Auth";

const WelcomeBannerEl = styled.section`
  background: #f1f1f1;
  display: flex;
  justify-content: center;
  padding: 40px 0 10px 0;

  div.welcome-banner-container {
    max-width: 1278px;
    width: 100%;
    background: #dc6126;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    box-shadow: 0px 3px 6px #0000001f;
    border-radius: 12px;

    p {
      color: #fff;
      font-size: 22px;
      line-height: 40px;
      letter-spacing: -0.7px;
      font-weight: 500;
      text-align: center;
    }

    button {
      background: #fff;
      border: solid 2px #dc6126;
      font-size: 19px;
      line-height: 30px;
      color: #dc6126;
      font-weight: 500;
      padding: 6px 30px;
      border-radius: 100px;
      transition: all 0.2s ease 0s;

      &:hover {
        cursor: pointer;
        border: solid 2px #fff;
        background: transparent;
        color: #fff;
      }
    }
  }
`;

const WelcomeBanner = () => {
  const { welcomeModal, setWelcomeModal } = useAuth();

  return (
    <WelcomeBannerEl>
      <div className="welcome-banner-container">
        <p>You haven't completed the welcome video.</p>
        <button
          onClick={() => {
            setWelcomeModal(true);
          }}
        >
          Show Welcome Video
        </button>
      </div>
    </WelcomeBannerEl>
  );
};

export default WelcomeBanner;
