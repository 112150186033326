import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import ReactGA from "react-ga4";

const vercelEnvironment = process.env.REACT_APP_VERCEL_ENV;

let environment;
let cuesURL;
let cmanURL;
let acgiURL;
let degreedURL;

if (vercelEnvironment == "production") {
  environment = 'production';
  cuesURL = "https://www.cues.org";
  cmanURL = "https://www.cumanagement.com";
  acgiURL = "https://online.cues.org/cuesssa"
  degreedURL = "https://degreed.com"
} else if (vercelEnvironment == "preview") {
  environment = 'staging';
  cuesURL = "https://stage.cues.org";
  cmanURL = "https://staging.cumanagement.com";
  acgiURL = "https://online.cues.org/cuesdevssa";
  degreedURL = "https://betatest.degreed.com"
} else if (vercelEnvironment === undefined) {
  environment = 'local';
  cuesURL = "https://stage.cues.org";
  cmanURL = "https://staging.cumanagement.com";
  acgiURL = "https://online.cues.org/cuesdevssa";
  degreedURL = "https://betatest.degreed.com"
}

axios.defaults.withCredentials = true;

const welcomeCookie = Cookies.get("welcome-modal");
const welcomeCompleteCookie = Cookies.get("welcome-complete");

const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [authenticated, setAuthenticated] = useState(false);
  const [cmpData, setCmpData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [welcomeModal, setWelcomeModal] = useState(false);
  const [welcomeComplete, setWelcomeComplete] = useState(false);
  const [env, setEnv] = useState(environment);

  const handleLogin = async () => {

    // Handle welcome modal logic.
    if (!welcomeCookie) {
      setWelcomeModal(true);
      // Set cookie so the modal isn't shown after the first time.
      Cookies.set("welcome-modal", "false", { expires: 3652 });
    }

    // Handle onboarding banner logic.
    if (welcomeCompleteCookie) {
      setWelcomeComplete(true);
    }

    if (environment == "local") {
      // Don't check for session cookie during local development.
      setAuthenticated(true);

      // Load in CMP dummy data during local development.
      setCmpData({
        "cmp_layout": [
        "cmp_benefits",
        "cmp_my_cues_learning",
        "cmp_events",
        "cmp_cues_net",
        "cmp_cu_management"
        ],
        "account_info": {
        "firstName": "CUES",
        "lastName": "Team",
        "fullName": "CUES Team",
        "employer": "CUES Staff",
        "position": "VP/Membership",
        "membershipLevel": "",
        "memberId": "1000157",
        "acgiRole": "Executive",
        "countryCode": null,
        "cmanPersonas": "Executive",
        "cuesPersonas": "Executive"
        },
        "benefits": {
        "allBenefits": [
        {
        "title": "CU Management Magazine",
        "description": "The latest CU news in print and digital - with articles covering all aspects of financial services.\r\n",
        "image": "https://cues.lndo.site/sites/default/files/2022-08/logo-benefits-cumanagement_0.png",
        "link": "https://cumanagement.com/magazine"
        },
        {
        "title": "CUES Councils",
        "description": "Learn directly from industry experts while connecting with peers at these in-person regional networking events.\r\n",
        "image": "https://cues.lndo.site/sites/default/files/2022-08/logo-benefits-councils.png",
        "link": "https://cues.org/Councils"
        },
        {
        "title": "CUES Director Education Center",
        "description": "Increase your competency on critical board topics with continually updated, interactive director education courses.\r\n",
        "image": "https://cues.lndo.site/sites/default/files/2022-08/logo-benefits-director.png",
        "link": "https://cues.org/cues-director-education-center"
        },
        {
        "title": "CUES Learning Portal",
        "description": "Access to pre-set learning pathways and curated content covering just about any business topic you can imagine. Managers—easily view the progress of your staff to aid in their development.\r\n",
        "image": "https://cues.lndo.site/sites/default/files/2022-08/logo-benefits-degreed.png",
        "link": "https://degreed.com/dgusermvjwjqg/dashboard"
        },
        {
        "title": "CUES Virtual Classroom",
        "description": "The closest thing to in-person learning, online! Participants can talk and interact with peers and experts, problem-solve, and build relationships, right from their desk.\r\n",
        "image": "https://cues.lndo.site/sites/default/files/2022-08/logo-benefits-virtual_0.png",
        "link": "https://cues.org/professional-development-and-events?title=Virtual+Classroom&field_event_type_value=All&field_filter_topics_target_id=All&field_persona_target_id=All&field_date_start_value=&field_date_end_value=&field_archived_content_value=1&field_state_province_list_target_id=All"
        },
        {
        "title": "CUESNet",
        "description": "An online networking tool connecting you with other CUES members. Join communities and get in on the conversation today!\r\n",
        "image": "https://cues.lndo.site/sites/default/files/2022-08/logo-benefits-cuesnet_0.png",
        "link": "https://cuesnet.cues.org/home"
        },
        {
        "title": "Compensation Surveys",
        "description": "Attract and retain well-qualified staff at every level when you benchmark your organization’s compensation against others based on asset size, region, and membership size.\r\n",
        "image": "https://cues.lndo.site/sites/default/files/2022-08/logo-benefits-compensation.png",
        "link": "https://cues.org/product-service?title=survey"
        },
        {
        "title": "Discounted Event Pricing",
        "description": "CUES offers the best in industry-specific educational events and you’ll save big with special member pricing.\r\n",
        "image": "https://cues.lndo.site/sites/default/files/2022-08/logo-benefits-pricing.png",
        "link": "https://cues.org/professional-development-and-events"
        },
        {
        "title": "Governance+",
        "description": "Easily deliver premium, in-depth education to all of your directors through pre-designed, multi-step, digital learning pathways.\r\n",
        "image": "https://cues.lndo.site/sites/default/files/2022-08/logo-benefits-governance.png",
        "link": "https://cues.org/membership/unlimited-plus/governance-plus"
        },
        {
        "title": "Harvard ManageMentor",
        "description": "Explore this suite of more than 40 online courses from Harvard Business Review. You’ll find essential topics under these main categories: Leading Yourself, Leading Others, and Leading the Business.\r\n",
        "image": "https://cues.lndo.site/sites/default/files/2022-08/logo-benefits-harvard_0.png",
        "link": "https://cues.myhbp.org/hmm12/home.html"
        },
        {
        "title": "Webinars",
        "description": "Meet your talent development needs anywhere, anytime with live or recorded educational webinars, offering hot topic presentations led by industry experts.\r\n",
        "image": "https://cues.lndo.site/sites/default/files/2022-08/logo-benefits-webinar.png",
        "link": "https://cues.org/professional-development-and-events?title=Webinar&field_event_type_value=Online+Event&field_filter_topics_target_id=All&field_persona_target_id=All&field_date_start_value=&field_date_end_value=&field_archived_content_value=1&field_state_province_list_target_id=All"
        }
        ],
        "keyBenefits": [
        {
        "title": "CU Management Magazine",
        "description": "The latest CU news in print and digital - with articles covering all aspects of financial services.\r\n",
        "image": "https://cues.lndo.site/sites/default/files/2022-08/logo-benefits-cumanagement_0.png",
        "link": "https://cumanagement.com/magazine"
        },
        {
        "title": "CUES Learning Portal",
        "description": "Access to pre-set learning pathways and curated content covering just about any business topic you can imagine. Managers—easily view the progress of your staff to aid in their development.\r\n",
        "image": "https://cues.lndo.site/sites/default/files/2022-08/logo-benefits-degreed.png",
        "link": "https://degreed.com/dgusermvjwjqg/dashboard"
        },
        {
        "title": "CUESNet",
        "description": "An online networking tool connecting you with other CUES members. Join communities and get in on the conversation today!\r\n",
        "image": "https://cues.lndo.site/sites/default/files/2022-08/logo-benefits-cuesnet_0.png",
        "link": "https://cuesnet.cues.org/home"
        },
        {
        "title": "Harvard ManageMentor",
        "description": "Explore this suite of more than 40 online courses from Harvard Business Review. You’ll find essential topics under these main categories: Leading Yourself, Leading Others, and Leading the Business.\r\n",
        "image": "https://cues.lndo.site/sites/default/files/2022-08/logo-benefits-harvard_0.png",
        "link": "https://cues.myhbp.org/hmm12/home.html"
        }
        ],
        "resources": [
        {
        "title": "CUES Leadership Development Guide",
        "description": "Learn the keys to building a strong leadership pipeline with this guide. You’ll discover how to easily ID the skills high potential future leaders need, and ways to guide them along the way.\r\n",
        "image": "https://cues.lndo.site/sites/default/files/2022-08/icn-benefits-guideleadership.png",
        "file": null,
        "link": "https://www.cues.org/leadership-development-guide-download"
        },
        {
        "title": "Welcome to Credit Union Leadership Guide",
        "description": "New to the CU movement? Download this guide for executives who are new to the industry and use this resource to help you embark on your new career.\r\n",
        "image": "https://cues.lndo.site/sites/default/files/2022-08/icn-benefits-welcome.png",
        "file": null,
        "link": "https://cues.org/welcome-credit-union-leadership-guide-download"
        },
        {
        "title": "Director Onboarding Tool Kit",
        "description": "A must for new board members! This guide explains a board member’s roles and responsibilities, covers credit union philosophy, and discusses board best practices.\r\n",
        "image": "https://cues.lndo.site/sites/default/files/2022-08/icn-benefits-onboarding.png",
        "file": null,
        "link": "https://cues.org/director-onboarding-tool-kit-download"
        },
        {
        "title": "Board Governance Assessment",
        "description": "Quickly and concisely gather honest feedback from board members on key areas of governance, and use the results to discover strengths, weaknesses, opportunities, and threats to prioritize and address.\r\n",
        "image": "https://cues.lndo.site/sites/default/files/2022-08/icn-benefits-report_0.png",
        "file": null,
        "link": "https://cues.org/product-service/board-governance-assessment"
        },
        {
        "title": "Guide to Effective Mentorship",
        "description": "Build strong, effective mentor relationships, and find tips on ways to find a good match in a mentor with the practical tools found in this guide.\r\n",
        "image": "https://cues.lndo.site/sites/default/files/2022-08/icn-benefits-guidemembership.png",
        "file": null,
        "link": "https://cues.org/effective-guide-mentorship-download"
        },
        {
        "title": "CUES State of Credit Union Training and Development Report",
        "description": "Download this whitepaper and discover findings from the CUES Talent Development Survey.\r\n",
        "image": "https://cues.lndo.site/sites/default/files/2022-08/icn-benefits-report.png",
        "file": null,
        "link": "https://cues.org/cues-state-credit-union-training-and-development-report-download"
        }
        ]
        },
        "articles": [
        {
        "title": "The State of Cannabis Banking in the Credit Union Space",
        "image": "https://www.cumanagement.com/sites/default/files/styles/panel_image/public/2022-09/marijuana-five-dollar-bill.jpg?itok=Ivw2YzUY",
        "date": "Sep 28, 2022\n",
        "type": "Blog",
        "author": "Stacy Litke",
        "url": "https://www.cumanagement.com/blogs/2022/09/28/state-cannabis-banking-credit-union-space",
        "length": "6 minutes",
        "roles": [
        "CEO",
        "Executive",
        "Manager",
        "Board",
        "Experienced Leaders &amp; Department Heads"
        ]
        },
        {
        "title": "Tech Time: Why One Rail Shouldn’t Rule Them All",
        "image": "https://www.cumanagement.com/sites/default/files/styles/panel_image/public/2022-09/real-time-payments-money.jpg?itok=fKFRZBTl",
        "date": "Sep 28, 2022\n",
        "type": "Article",
        "author": "Abhishek Veeraghanta",
        "url": "https://www.cumanagement.com/articles/2022/09/tech-time-why-one-rail-shouldnt-rule-them-all",
        "length": "6 minutes",
        "roles": [
        "CEO",
        "Executive"
        ]
        },
        {
        "title": "Good Governance: Recruiting Members to Your Board",
        "image": "https://www.cumanagement.com/sites/default/files/styles/panel_image/public/2022-09/businessmen-handshake.jpg?itok=rTP0BAc-",
        "date": "Sep 27, 2022\n",
        "type": "Article",
        "author": "Diane Franklin",
        "url": "https://www.cumanagement.com/articles/2022/09/good-governance-recruiting-members-your-board",
        "length": "7 minutes",
        "roles": [
        "CEO",
        "Executive",
        "Board",
        "Experienced Leaders &amp; Department Heads",
        "Board Liaisons"
        ]
        }
        ],
        "pages": {
        "invalid_membership": {
        "title": "Invalid Membership",
        "body": "<p>You don't have an active CUES membership. Please visit CUES.org to subcribe.</p>\r\n\r\n<p><a href=\"https://www.cues.org/membership\">Visit CUES</a></p>\r\n"
        }
        }
        });

      setTimeout(() => {
        setLoading(false);
      }, 1500);
    } else {
      // fetch the cmp endpoint to determine if the user is logged in.
      let cmpEndpoint = cuesURL + '/cmp';

      axios
        .get(cmpEndpoint)
        .then(function (response) {
          setAuthenticated(true);
          setCmpData(response.data);
          setLoading(false);
        })
        .catch(function (error) {
          setAuthenticated(false);
          setLoading(false);
        });
    }
  };

  const value = {
    authenticated,
    cmpData,
    loading,
    environment,
    handleLogin,
    welcomeModal,
    setWelcomeModal,
    welcomeComplete,
    setWelcomeComplete,
    cuesURL,
    cmanURL,
    acgiURL,
    degreedURL
  };

  useEffect(() => {
    handleLogin();
  }, [authenticated]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { useAuth, AuthProvider };
