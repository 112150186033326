import React, { useEffect } from "react";
import { useAuth } from "./Auth";

import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const { authenticated, loading } = useAuth();
  const location = useLocation();

  if (!authenticated && !loading) {
    return <Navigate to="/login" replace />
  }

  return children;
};

export default ProtectedRoute;
