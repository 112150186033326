import React, { createContext } from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./Auth";
import App from "./App";
import Login from "./routes/Login";
import ProtectedRoute from "./ProtectedRoute";
import Events from "./routes/Events/Events";
import ManagementSection from './routes/CU-Managment/CU-ManagmentSection';
const rootElement = document.getElementById("root");

Sentry.init({
  dsn: "https://3b8b4fe1f1bb4827afeec764c6b770cf@o465786.ingest.sentry.io/6606267",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <App />
            </ProtectedRoute>
          }
        ></Route>
        
        <Route path="/login" element={<Login />}></Route>
      </Routes>
    </AuthProvider>
  </BrowserRouter>,
  rootElement
);
